/* * {
    border: thin solid red;
} */

.prototype {
    /* border: thin solid red; */
    margin-top : 10px;
    padding: 0 20px;
    background-color: wheat;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.prototype a {
    font-size: large;
}